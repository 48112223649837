import moment from "moment";
export const dateConvert =  {
    method:{

        convertSQLStringToStringFormated(date, prmFormat){
            if (date!==undefined && date!==null) {
                //2023-12-20+03:00
                const arrMain = date.split("+");
                //const splDate =arrMain[0].split("-");
                //const newDate = splDate[2]+"-"+splDate[1]+"-"+splDate[0]
                return moment(arrMain[0]).format(prmFormat);
            }
            return "";
        },

        convertToDateFormated(date, prmFormat){
            if (date!==undefined && date!==null && date+""!=="Invalid Date") {
                return moment(date).format(prmFormat);
            }
            return "";
        },
        convertToDate(date){
            return moment(date).format('DD-MM-YYYY');
        },
        convertToDateTime(date) {
            return moment(date).format('DD-MM-YYYY HH:mm:ss');
        },
        convertToDateYM(date){
            const splDate =date.split("-");//.substring(0, 10)
            const newDate = splDate[1]+"-"+splDate[0]+"-"+splDate[2]
            return moment(newDate).format('YYYY-MM-DD');
        },
        flatpickerToDateYM(date){
            date = date.substring(0, 10);
            const splDate =date.split("-");//.substring(0, 10)
            const newDate = splDate[2]+"-"+splDate[1]+"-"+splDate[0]
            const date2 = new Date(newDate);
            return moment(date2).format('YYYY-MM-DD');
        },

        dateToflatpickerFormated(date, prmFormat){
            moment.locale('tr')
            return moment(date).format(prmFormat);
        },
        dateToflatpicker(date){
            moment.locale('tr')
            return moment(date).format('DD-MM-YYYY (ddd)');
        },
        getTodayYMD(){
            const today = new Date();
            return moment(today).format('YYYY-MM-DD');
        },
        getTodayYM(){
            const today = new Date();
            return moment(today).format('YYYY-MM');
        },

        getThisYear(){
            const today = new Date();
            return moment(today).format('YYYY');
        },

        getTodayYMtr(date){
            moment.locale('tr')
            return moment(date).format('MMMM-YY');
        },
        getDayInMonth(date){
            return moment(date, "YYYY-MM").daysInMonth()
        },

        addDayToDateYMD(date,add){
            return moment(date).add(add, 'days').format('YYYY-MM-DD');
        },

        addMonthToDateYM(date,add){
            return moment(date).add(add, 'months').format('YYYY-MM');
        },
        addMonthToDateYMD(date,add){
            return moment(date).add(add, 'months').format('YYYY-MM-01');
        },
        addDayMonthToDateYMD(date,add,day){
            return moment(date).add(add, 'months').add(day, 'days').format('YYYY-MM-DD');
        },
    }
};